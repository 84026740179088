import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";

export const getCareerList = data => {
  return api({
    url: '/admin/employ/career/index',
    method: 'post',
    data
  });
};


export const softDelete = data => {
    return api({
      url: '/admin/employ/career/softDelete',
      method: 'post',
      data
    });
  };
  export const putBack = data => {
    return api({
      url: '/admin/employ/career/putBack',
      method: 'post',
      data
    });
  };
  export const del = data => {
    return api({
      url: '/admin/employ/career/delete',
      method: 'post',
      data
    });
  };
  
export const getCareerDetail = data=>{
  return api({
    url: '/admin/employ/career/detail',
    method: 'post',
    data
  });
} 
export const getCompanyDetail = data=>{
  return api({
    url: '/admin/employ/company/detail',
    method: 'post',
    data
  });
} 
export const saveCareer = data =>{
  return api({
    url: '/admin/employ/career/save',
    method: 'post',
    data
  });
} 
export const getCategories = data =>{
  return api({
    url: '/admin/employ/career/categories',
    method: 'post',
    data
  });
} 
//会员excel模板下载
export const CareerListExample = () => {
  return fetchFile({
    url: '/admin/employ/career/importXlsx',
    method: 'post',
    download: true,
    data: {},
  })
}
export const importCareer = (data) => {
  return api({
    url: '/admin/employ/career/import',
    method: 'post',
    data,
    notCancel: true,
  })
}
export const exportCareer = (data) => {
  return fetchFile({
    url: '/admin/employ/career/exportIndex',
    method: 'post',
    data,
    download: true,
  })
}

export const saveTag = (data) => {
  return api({
    url: '/admin/employ/career/saveTag',
    method: 'post',
    data,
  })
}
export const isTop = (data) => {
  return api({
    url: '/admin/employ/career/isTop',
    method: 'post',
    data,
  })
}

export const careerTypes = (data) => {
  return api({
    url: '/admin/employ/career/careerTypes',
    method: 'post',
    data,
  })
}
export const saveAddress = (data) => {
  return api({
    url: '/admin/employ/career/saveAddress',
    method: 'post',
    data,
  })
}
