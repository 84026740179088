<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :onExport="onExport">
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
      @submit.native.prevent
    >
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="岗位名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="岗位类型：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择 ">
            <el-option
              v-for="item in categoryIdArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="岗位行业：" prop="company_category">-->
<!--          <el-cascader-->
<!--            placeholder="请选择，可搜索"-->
<!--            :value="form.company_category.split(',')"-->
<!--            :options="companyCategoryArr"-->
<!--            @change="(val) => (form.company_category = val.join(','))"-->
<!--            clearable-->
<!--            filterable-->
<!--            :props="{-->
<!--              checkStrictly: true,-->
<!--              label: 'name',-->
<!--              value: 'name',-->
<!--              children: 'children',-->
<!--              expandTrigger: 'hover',-->
<!--            }"-->
<!--          ></el-cascader>-->
<!--        </el-form-item>-->
      </div>
      <div class="filter-item">
        <el-form-item label="是否置顶：" prop="is_top">
          <el-select v-model="form.is_top" placeholder="请选择 ">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="岗位薪资：">
          <el-col :span="11" class="flex-align-center">
            <el-form-item>
              <el-input
              type="number"
    
                v-model="form.min_wages"
                placeholder="请输入最低薪资"
              ></el-input>
            </el-form-item>
            &nbsp;&nbsp;K
          </el-col>
          <el-col class="line flex-center" :span="2">-</el-col>
          <el-col :span="11" class="flex-align-center">
            <el-form-item>
              <el-input
                v-model="form.max_wages"
                placeholder="请输入最高薪资"
              ></el-input>
            </el-form-item>
            &nbsp;&nbsp;K
          </el-col>
        </el-form-item> -->
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="发布时间："
          start-prop="create_start_time"
          :start-time.sync="form.create_start_time"
          end-prop="create_end_time"
          :end-time.sync="form.create_end_time"
        />
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="截止时间："
          start-prop="close_start_time"
          :start-time.sync="form.close_start_time"
          end-prop="close_end_time"
          :end-time.sync="form.close_end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import {
  exportCareer,
  getCategories,
} from '@/modules/employ/api/employ-career-list'
import { categoryList } from '@/modules/employ/api/employ-career-category-list'
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      categoryIdArr: [{ name: '全部', id: -1 }],
      companyCategoryArr: [],
    }
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },

    //筛选
    ok() {
      this.uploadFilter(this.form)
    },

    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page }
      exportCareer(postData).catch((err) => {})
    },
  },
  created() {
    getCategories().then((res) => {
      this.companyCategoryArr = [...res.data]
    })
    categoryList().then((res) => {
      this.categoryIdArr = [{ name: '全部', id: -1 }, ...res.data.list]
    })
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
}
</script>
