var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('list-layout',{ref:"employCareerListRefs",attrs:{"filter-form":_vm.form,"thead":_vm.thead,"tab-arr":_vm.tabArr,"on-fetch":_vm.getList,"on-delete":_vm.removeListItem,"on-edit":_vm.editListItem,"onPutBack":_vm.onPutBack},on:{"command":_vm.onCommand},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.addListItem}},[_vm._v("新建岗位")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.isShowImportCareer = true}}},[_vm._v("批量导入岗位")])],1)]},proxy:true},{key:"filter",fn:function(ref){
var pageData = ref.pageData;
var idList = ref.idList;
return [_c('ListFilter',{attrs:{"filterForm":_vm.form,"page":pageData.page,"uploadFilter":_vm.ok,"ids":idList}})]}},{key:"item_is_top",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.is_top ? '已置顶' : '未置顶'))])]}},{key:"item_min_wages",fn:function(ref){
var row = ref.row;
return [(row.min_wages != 0 && row.max_wages != 0)?[_c('span',[_vm._v(_vm._s(row.min_wages)+"K-"+_vm._s(row.max_wages)+"K")])]:[_vm._v("面议")]]}}])}),_c('Pagination',{attrs:{"total":_vm.pageData.total,"page":_vm.pageData.current_page,"change":_vm.changPage}}),_c('ImportCareer',{attrs:{"updateList":_vm.onRefresh},model:{value:(_vm.isShowImportCareer),callback:function ($$v) {_vm.isShowImportCareer=$$v},expression:"isShowImportCareer"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }