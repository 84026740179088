<template>
  <div class="page">
    <list-layout
      ref="employCareerListRefs"
      :filter-form="form"
      :thead="thead"
      :tab-arr="tabArr"
      :on-fetch="getList"
      :on-delete="removeListItem"
      :on-edit="editListItem"
      :onPutBack="onPutBack"
      @command="onCommand"
    >
      <template #top>
        <div style="margin-bottom: 20px">
          <el-button type="primary" size="small" @click="addListItem"
            >新建岗位</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="isShowImportCareer = true"
            >批量导入岗位</el-button
          >
        </div>
      </template>
      <template #filter="{ pageData, idList }">
        <ListFilter
          :filterForm="form"
          :page="pageData.page"
          :uploadFilter="ok"
          :ids="idList"
        />
      </template>
      <template #item_is_top="{ row }">
        <span>{{ row.is_top ? '已置顶' : '未置顶' }}</span>
      </template>
      <template #item_min_wages="{ row }">
        <template v-if="row.min_wages != 0 && row.max_wages != 0">
          <span>{{ row.min_wages }}K-{{ row.max_wages }}K</span>
        </template>
        <template v-else>面议</template>
      </template>
    </list-layout>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <ImportCareer v-model="isShowImportCareer" :updateList="onRefresh" />
  </div>
</template>

<script>
import {
  getCareerList,
  del,
  softDelete,
  putBack,
  isTop,
} from '@/modules/employ/api/employ-career-list'
import ListFilter from '@/modules/employ/components/employ-career-list/ListFilter.vue'
import ListLayout from '@/base/layout/ListLayout'
import Pagination from '@/base/components/Default/Pagination'
import ImportCareer from '@/modules/employ/components/employ-career-list/ImportCareer'
export default {
  components: {
    ListLayout,
    ListFilter,
    Pagination,
    ImportCareer,
  },

  data() {
    return {
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      form: {
        tab: 'normal',
        page: 1,
        page_size: 20,
        keyword: '',
        category_id: -1,
        company_category: '',
        create_start_time: -1,
        create_end_time: -1,
        close_start_time: -1,
        close_end_time: -1,
        is_top: -1,
        // min_wages:'',
        // max_wages:'',
      },
      // 弹窗不需要显示操作列，需要重新声明覆盖掉
      thead: [
        { type: 'selection' },
        { label: '岗位名称', prop: 'name', minWidth: 100 },
        { label: '所属企业', prop: 'company_name', minWidth: 100 },
        { label: '要求学历', prop: 'education_text', minWidth: 100 },
        { label: '岗位类型', prop: 'category_name', minWidth: 100 },
        { label: '岗位地区', prop: 'region', minWidth: 100 },
        // { label: '岗位行业', prop: 'company_category', minWidth: 100 },
        {
          label: '岗位薪资',
          prop: 'min_wages',
          type: 'slot',
          minWidth: 100,
        },
        {
          label: '投递数',
          prop: 'collects_count',
          minWidth: 120,
          sortable: true,
        },
        {
          label: '是否置顶',
          prop: 'is_top',
          type: 'slot',
          minWidth: 100,
        },
        // {
        //   label: '收藏数',
        //   prop: 'careers_count',
        //   minWidth: 120,
        //   sortable: true,
        // },
        { label: '发布时间', prop: 'create_time', minWidth: 180 },
        { label: '截止时间', prop: 'close_time', minWidth: 180 },
        {
          label: '操作',
          type: 'operation',
          width: 180,
          operations: [
            {
              command: 'top',
              text: '置顶',
              visible: (row, form) => !row.is_top,
            },
            {
              command: 'noTop',
              text: '取消置顶',
              visible: (row, form) => row.is_top,
            },
            {
              command: 'edit',
              text: '编辑',
              visible: (r, q) => q.tab !== 'deleted',
            },
            {
              command: 'putback',
              visible: (row, form) => form.tab === 'deleted',
            },
            { command: 'delete' },
          ],
        },
      ],
      pageData: {
        page_size: 15,
        tab: 'normal',
      },
      isShowImportCareer: false,
      selectArr: [],
    }
  },
  methods: {
    isTop(row) {
      isTop(row).then((res) => {
        this.onRefresh()
      })
    },
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
        })
      }
    },
    // 数据删除
    removeListItem(idList, forever = false) {
      console.log(idList, forever)
      return forever ? del({ id: idList }) : softDelete({ id: idList })
    },
    /**
     * 编辑
     * @param{string} mode  弹窗还是抽屉显示
     */
    editListItem(row) {
      this.$router.push({
        name: 'EmployCareerEdit',
        params: { id: row.id },
      })
    },
    addListItem() {
      this.$router.push({
        name: 'EmployCareerAdd',
      })
    },
    //  还原
    onPutBack(idList) {
      return putBack({ id: idList })
    },
    // 新增、编辑保存成功后刷新
    onRefresh() {
      this.$refs.employCareerListRefs?.getList({ page: 1 })
    },

    // 筛选回调
    ok(e) {
      this.onRefresh()
    },
    // 拉取数据
    getList(query) {
      return getCareerList(query)
    },
    changPage(e) {
      this.form.page_size = e.page_size
      const pageData = { ...this.form, ...e }
      this.getList(pageData)
    },
    onCommand({ command, row }) {
      switch (command) {
        case 'noTop':
        case 'top':
          this.isTop({ id: row.id, is_top: row.is_top ? 0 : 1 })
          break
        default:
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  position: absolute;
  right: 60px;
  top: 110px;
}
</style>
