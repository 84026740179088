<!--
 * @Author: dongjia
 * @Date: 2021-08-05 13:45:19
 * @LastEditTime: 2021-09-23 17:44:32
 * @LastEditors: aleaner
 * @Description: 导入岗位弹窗
 * @FilePath: \organization-admin\components\Member\ImportMembers.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    :title="handleSaaSVersionKeyword(`批量导入岗位`)"
    :visible.sync="openDialog"
    :width="'600px'"
    class="dialog-vertical"
    close-on-click-modal
    close-on-press-escape
  >
    <div class="dialog-content" :class="[tenantVersion]">
      <div class="content-title">请按以下步骤操作：</div>
      <div class="content-step">
        步骤1：下载《批量导入{{
          handleSaaSVersionKeyword('岗位')
        }}模板》，按模板中的说明进行填写。
        <el-button
          class="btn"
          type="text"
          style="margin-left: 10px"
          @click="downloadExcel"
          >下载模板</el-button
        >
      </div>
      <div class="content-step">
        步骤2：上传文件后系统会自动进行批量导入{{
          handleSaaSVersionKeyword('岗位')
        }}。
      </div>
    </div>
    <div slot="footer">
      <div class="footer-btn">
        <el-button size="small" @click="openDialog = false">关闭</el-button>
        <el-upload
          ref="excelExplorer"
          accept=".xlsx, .xls"
          action="#"
          :limit="1"
          :http-request="customHandler"
          :show-file-list="false"
          :disabled="loading"
        >
          <el-button
            size="small"
            type="primary"
            icon="el-icon-upload2"
            style="min-width: 100px"
            :loading="loading"
            >{{
              loading ? (progress ? `${progress}%` : '导入中') : '上传文件'
            }}</el-button
          >
        </el-upload>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  importCareer,
  CareerListExample,
} from '@/modules/employ/api/employ-career-list'
/* load the codepage support library for extended support with older formats  */
import { set_cptable } from 'xlsx'
import * as cptable from 'xlsx/dist/cpexcel.full.mjs'
import {
  readXlsxFileData,
  runPromiseByQueue,
  sliceArrFn,
} from '@/base/utils/tool'

set_cptable(cptable)

export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      detailForm: null,
      templateFileOne: '',
      templateFileTwo: '',
      downloadUrl:
        window.serverConfig.VUE_APP_ADMINURL +
        '/cyc/files/批量导入未认证校友模板.xlsx',
      loading: false,
      progress: 0,
    }
  },
  methods: {
    customHandler(e) {
      this.loading = true
      readXlsxFileData(
        e.file,
        (data, titles, list) => {
          this.$refs.excelExplorer.clearFiles()
          const size = 100
          const sliceArr = sliceArrFn(list, size)

          const errMsgArr = []
          let count = 0

          const queue = runPromiseByQueue(
            sliceArr.map((s, sI) => {
              return () => {
                return new Promise((resolve, reject) => {
                  importCareer({
                    titles,
                    list: s,
                    // excel 里面 行号 3 才是真实数据开始
                    start_row: sI * size + 3,
                  })
                    .then((res) => {
                      if (!res.data.status) {
                        /* 记录不同的导入失败原因 */
                        if (
                          !errMsgArr.find((errText) => errText === res.data.msg)
                        ) {
                          errMsgArr.push(res.data.msg)
                        }
                      }
                      count++
                      if (count === sliceArr.length) {
                        this.progress = 100
                      } else {
                        this.progress = Math.floor(
                          (count * 100) / sliceArr.length
                        )
                      }
                      resolve()
                    })
                    .catch((err) => {
                      reject(err)
                    })
                })
              }
            })
          )

          Promise.all([queue])
            .then(() => {
              if (errMsgArr.length) {
                this.$msgbox
                  .confirm(errMsgArr.join('；<br/>'), '导入失败', {
                    type: 'error',
                    showCancelButton: false,
                    dangerouslyUseHTMLString: true,
                  })
                  .then(() => {})
                  .catch(() => {})
              } else {
                this.$message.success('导入成功')
              }
              this.update()
            })
            .catch(() => {
              this.loading = false
            })
        },
        () => {
          this.loading = false
          this.$refs.excelExplorer.clearFiles()
        }
      )
    },
    // 下载导入岗位文件模板
    downloadExcel() {
      CareerListExample()
    },
    // 导入完成操作
    update() {
      this.openDialog = false
      this.updateList()
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
  watch: {
    open(newValue) {
      if (newValue) {
        this.loading = false
        this.progress = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content-title {
  line-height: 1;
  color: #3a3a3a;
}

.dialog-content.school {
  .content-step {
    display: block;
    margin-top: $space;
  }

  .el-link {
    vertical-align: baseline;
  }
}

.content-step {
  color: #3a3a3a;
  margin-top: 20px;

  .btn {
    padding: 0;
    margin-right: 10px;
  }
}
.footer-btn {
  display: flex;
  float: right;
  padding-bottom: 20px;
  .el-button {
    margin-left: 20px;
  }
}
</style>
<style>
.el-message-box__status {
  top: 12px;
}

.el-message-box__message {
  white-space: pre-wrap;
}
</style>
